<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="d-flex">
    <SubMenu :nameModule="nameModule" :subMenu="subMenu"/>

    <!-- Seccion para cargar el contenido de la navegación del modulo-->
    <v-slide-y-transition mode="out-in">
      <router-view :class="screenSmaller ? 'ml-14' : 'ml-0'"></router-view>
    </v-slide-y-transition>
  </div>
</template>
   
<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import SubMenu from '@/components/SubMenuAffiliates.vue';
import { Role } from "../../../router-external/role.js";

export default {
  name: 'Affiliates',
  components: { SubMenu },
  data: () => ({
    screenSmaller: false,
    tab: null,
    nameModule: "Afiliados",
    userRoles: {},
    roles: Role,
    subMenu: [
      {
        name: "Solicitar entrega",
        icon: "edit_note",
        rol: Role.Afiliados_Solicitud,
        link: { name: 'external.login.modules.affiliates.requests.entrega' }
      },
      {
        name: "Historial",
        icon: "history",
        rol: Role.Afiliados_Solicitud,
        link: { name: 'external.login.modules.affiliates.historys.historial' }
      },
    ],

  }),
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.screenSmaller = window.innerWidth <= 542;
    }
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped></style>