<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Marzo 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-navigation-drawer class="blue-grey lighten-5" permanent hide-overlay :mini-variant.sync="mini" :absolute="screenSmaller ? true : false">
    <v-list class="pa-0" dense height="38px">
      <v-list-item>
        <v-list-item-action class="mt-0 mb-0">
          <v-icon color="#0d47a1">person</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title><span class="modulo">{{ nameModule }}</span></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="ma-0">
          <v-icon @click.stop="mini = !mini" color="#0d47a1">compare_arrows</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list nav dense>
      <template v-for="({ name, link, icon, rol }, i) in subMenu">
        <v-list-item v-if="userRoles.includes(rol) && screenSmaller" :key="i" :to="link" @click.stop="mini = !mini">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="userRoles.includes(rol) && !screenSmaller" :key="i" :to="link">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>

</template>
  
<!-- #################################################################################### -->
<!-- ###### Sección de scripts                                                     ###### -->
<!-- #################################################################################### -->

<script>
import { mapState } from "vuex";

export default {
  name: 'SubMenuAffiliates',
  props: {
    subMenu: {
      type: Array,
      default: () => []
    },
    nameModule: String
  },
  data() {
    return {
      mini: false,
      screenSmaller: false,
      userRoles: {}
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth"]),
  },
  watch: {
    'screenSmaller': function () {
      if(this.screenSmaller === true) {
        this.mini = true
      } else {
        this.mini = false
      }
    }
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.screenSmaller = window.innerWidth <= 542;
    }
  },

}
</script>
  
<!-- #################################################################################### -->
<!-- ###### Sección de estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.v-navigation-drawer {
  height: 91vh !important;
}

.modulo {
  font-size: medium;
}

.v-list-item--active {
  color: #0a5cb8 !important;
}
.v-icon{
  color: #a3a8b7;
}
</style>